import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/katar/" className="sel">
              {" "}
              &gt; Katar
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="related">
                <p className="name">Dowiedz się więcej</p>
                <div className="line">
                  <a href="/poradnik/katar/katar_alergiczny_-_poznaj_i_zapobiegaj/">
                    <div className="c1">
                      <span className="photo"
                   style={{"background-image":"url('/web/kcfinder/uploads/files/shutterstock_1206612040.jpg')"}} />
                   
                    </div>
                    <div className="c2">
                      Katar alergiczny - poznaj i zapobiegaj{" "}
                    </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/poradnik/katar/podejmij_szybka_walke_z_katarem/">
                    <div className="c1">
                      <span className="photo" style={{"background-image":"url('/web/kcfinder/uploads/files/shutterstock_152527238.jpg')"}} />
                   
                    </div>
                    <div className="c2">Podejmij szybką walkę z katarem </div>
                  </a>
                </div>
              </div>
              <a href="/dobierz-lek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/zrobtest.png" />
              </a>
              <a href="/reklama-telewizyjna/" className="link_box video">
                <img src="/web/kcfinder/uploads/files/box/SudafedHAthumbnail.jpg" />
              </a>
              <a href="/katar/" className="quiz_btn" />
            </div>
            <div className="col2">
              <div className="wysiwyg">
                <div>
                  <img
                    alt
                    src="/web/kcfinder/uploads/images/headingkatar.png"
                    style={{
                      width: "195px",
                      height: "auto"
                    }}
                  />
                </div>
                <div>&nbsp;</div>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Katar to potoczna nazwa{" "}
                  <a
                    href="/slowniczek/#zapalenia_blony_sluzowej_nosa"
                    target="_blank"
                  >
                    zapalenia błony śluzowej nosa
                  </a>{" "}
                  lub{" "}
                  <a href="/slowniczek/#niezyt_nosa" target="_blank">
                    nieżytu nosa.
                  </a>{" "}
                  Spowodowany jest w większości przypadków infekcją wirusową (aż
                  90%<sup>1</sup>), rzadziej przyczyną mogą być też{" "}
                  <a href="/slowniczek/#alergen" target="_blank">
                    alergeny
                  </a>{" "}
                  lub bakterie.
                </div>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </div>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Przesuszenie śluzówki i osłabienie organizmu powoduje
                  przenikanie wirusów i rozwój stanu zapalnego. Przedostanie się
                  do organizmu szkodliwych czynników zewnętrznych wywołuje
                  reakcję obronną naszego{" "}
                  <a href="/slowniczek/#uklad_immunologiczny" target="_blank">
                    układu immunologicznego
                  </a>
                  .
                </div>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </div>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Początkowo pojawia się wodnista wydzielina, która wraz z
                  postępowaniem infekcji gęstnieje. Znajdujące się w{" "}
                  <a href="/slowniczek/#blona_sluzowa_nosa" target="_blank">
                    błonie śluzowej
                  </a>{" "}
                  naczynia krwionośne rozszerzają się powodując obrzęk
                  skutkujący uczuciem zatkanego nosa i problemami w oddychaniu.
                </div>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </div>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Bardzo ważne jest dbanie o prawidłową drożność i nawilżanie
                  nosa podczas kataru. Odblokowanie nosa ułatwia usunięcie z
                  niego nadmiaru wydzieliny, a tym samym zawartych w niej
                  wirusów. Udrożnione drogi oddechowe zapewniają swobodny oddech
                  podczas snu, który jest bardzo istotny dla regeneracji
                  organizmu. Nawilżona błona śluzowa nosa sprzyja większemu
                  komfortowi oddychania podczas kataru i zmniejsza ryzyko
                  przenikania wirusów do organizmu.
                </div>
                <div>&nbsp;</div>
                <div>
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    <sup>1</sup>Dr n. med. Joanna Lange, Lek. Honorata Marczak,
                    Infekcje dróg oddechowych u dzieci, portal Medycyna
                    praktyczna;
                    http://pediatria.mp.pl/choroby/ukladoddechowy/137909,infekcje-drog-oddechowych-u-dzieci&nbsp;
                  </span>
                </div>
                <div>&nbsp;</div>
              </div>
              <div className="drugs">
              <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/xylosprayha.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> <br />
                      XYLOSPRAY HA
                    </p>
                    <div className="desc">
                      Jako jedyny
                      <span className="note_nr" /> zawiera aż 3 substancje,
                      które nawilżają: kwas hialuronowy (w postaci soli
                      sodowej), sorbitol, glicerol.
                    </div>
                    <a href="/produkty/sudafed_xylospray_ha/" className="more">
                      WIĘCEJ
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/Sudafed_DEX_kartonik%2Bbutelka%20.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> <br />
                      XYLOSPRAY DEX
                    </p>
                    <div className="desc">
                    Odblokowuje nos, przyspiesza gojenie ran i chroni podrażnioną błonę śluzową nosa
                    </div>
                    <a href="/produkty/sudafed_xylospray_dex/" className="more">
                      WIĘCEJ
                    </a>
                  </div>
                </div>
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/xylospray.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> <br />
                      XYLOSPRAY
                    </p>
                    <div className="desc">
                      Szybko odblokowuje nos i zatoki oraz ułatwia usunięcie
                      zalegającej wydzieliny.
                    </div>
                    <a href="/produkty/sudafed_xylospray/" className="more">
                      WIĘCEJ
                    </a>
                  </div>
                </div>
                
              </div>
              <div className="clear note">
                <p clss="oo">
                  1 Wśród produktów zawierających Xylometazolinę/Oxymetazolinę w
                  postaci aerozolu do nosa, dopuszczonych do obrotu w Polsce
                  według Urzędowego Wykazu Produktów Leczniczych Dopuszczonych
                  do Obrotu na terytorium Rzeczypospolitej Polskiej, według
                  stanu na dzień 1 Lutego 2023 r.
                </p>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px"
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow"> Ustawienia plików Cookie </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
